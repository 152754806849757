import React from "react";
import { PageProps } from "gatsby";
import DefaultLayout from "../../layouts/DefaultLayout";
import Image from "../../components/Image";

const JoineryColumn: React.FC = ({ children }) => (
  <div className="col-md-6 pb-3">{children}</div>
);

interface JoineryCardProps {
  title: string;
}

const JoineryCard: React.FC<JoineryCardProps> = ({ title }) => {
  const name = `join_` + title.replace(/ /g, "_").toLowerCase();

  return (
    <div className="card h-100">
      <div className="card-image">
        <Image name={name} />
      </div>
      <div className="card-body">
        <p className="card-text">{title}</p>
      </div>
    </div>
  );
};

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout pathname={location.pathname} pageTitle="Joinery">
    <div className="container my-5">
      <p className="text-size-medium">
        We have undertaken a number of joinery projects on behalf of our
        clients. We can produce various items in all timbers to suit your needs.
      </p>
    </div>
    <div className="container my-3">
      <div className="row justify-content-center">
        <JoineryColumn>
          <JoineryCard title="Stair Treads" />
        </JoineryColumn>
        <JoineryColumn>
          <JoineryCard title="Specialised Joinery" />
        </JoineryColumn>
        <JoineryColumn>
          <JoineryCard title="Hand Rails" />
        </JoineryColumn>
        <JoineryColumn>
          <JoineryCard title="Commercial Joinery" />
        </JoineryColumn>
      </div>
    </div>
  </DefaultLayout>
);

export default Page;
